<template>
    <v-hover v-slot:default="{hover}">
        <v-card
            class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
            :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="6">
                        <span class="font-weight-bold"> {{item.modified_date}} •
                            <span class="gold--text">{{ g_isDefined(item.agreement) }}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="6" class="font-weight-medium">
                        <span class="gold white--text px-3 rounded">{{item.type}}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state"> {{item.deposit_type}}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12" :xs="12" :sm="12" :md="6">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ g_isDefined(item.customer) }}</span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" :xs="12" :sm="12" :md="6">
                        <v-row align="center" no-gutters>
                            <span class="pr-2">{{ g_i18_keys('serial_number') }}</span>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ g_isDefined(item.serial_number) }}</span>
                        </v-row>
                    </v-col>

                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="'width: calc(100% /' + keys.length + ')'">
                                <v-row no-gutters >
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>
                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{item}}</span>
                    </v-col>
                    <!--FOOTER-->
                    <v-col cols="12" class="pt-2">
                        <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                            <v-icon size="16" class="pr-3">mdi-comment-text</v-icon>
                            <span class="caption">{{ g_isDefined(item.comment) }}</span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "ProvisionCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    computed: {},
    methods: {}
}
</script>